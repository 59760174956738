import styled from "@emotion/styled";
import { Divider } from "@mui/material";

export const CustomDivider = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const DataRow = styled.div`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 10px 20px;
`;

export const MainContainer = styled.div`
  padding: 20px;
`;

export const RowField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FieldLabel = styled.div`
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
  margin-right: 10px;
`;

export const FieldValue = styled.div`
  color: #494949;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
`;
