import React from 'react';
import {Stack} from "@mui/material";
import { Pagination } from '@mui/material'




const CustomPagination = ({count, pageNo, onChange}) => {
    return (
        <Stack spacing={2} alignSelf={"center"}>
            <Pagination count={count} variant="outlined" shape="rounded" color="primary" page={pageNo}
                        onChange={onChange} />
        </Stack>
    );
};

export default CustomPagination;