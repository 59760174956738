import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { COOKIE_GOOGLE } from "../common/constants";

export const getTokenFromCookies = () => {
  const token = Cookies.get(COOKIE_GOOGLE);
  return token;
};

export const usersApiSlice = createApi({
  reducerPath: "users",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/v1/admin`,
  }),
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
        url: "/",
        method: "GET",
        // body: data,
        headers: {
          Authorization: `Bearer ${getTokenFromCookies()}`,
        },
      }),
      transformResponse: (res) => {
        if (res) {
          return res;
        } else {
          return null;
        }
      },

      providesTags: ["Users"],
    }),

    addUser: builder.mutation({
      query: (userData) => ({
        url: `/`,
        method: "POST",
        body: {
          ...userData,
        },
        headers: {
          Authorization: `Bearer ${getTokenFromCookies()}`,
        },
      }),
      invalidatesTags: ["Users"],
    }),
    editUser: builder.mutation({
      query: ({ id, ...patch }) => {
        return {
          url: `/${id}`,
          method: "PATCH",
          body: patch.data,
          headers: {
            Authorization: `Bearer ${getTokenFromCookies()}`,
          },
        };
      },
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/${userId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getTokenFromCookies()}`,
        },
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
} = usersApiSlice;
