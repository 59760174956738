import React, { useEffect, useState } from "react";
import "./styles/register.css";
import { FcGoogle } from "react-icons/fc";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleOneTapLogin } from "@react-oauth/google";

import bg from "../images/login-bg.png";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { COOKIE_GOOGLE, LS_USER_PROFILE } from "../common/constants";

const MainContainer = styled.div`
  // position: relative;
  width: 100vw;
  height: 100vh;
  // background-color: #ffdbd1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const LogoImage = styled.img``;

const SignInSection = styled.div`
  padding: 100px 70px;
  margin: auto;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 1px 8px 0px #ffa186;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
`;

const HeaderText = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 44.8px */
  letter-spacing: 0.32px;
`;

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let cookie = Cookies.get(COOKIE_GOOGLE);
    if (cookie) {
      navigate("/organizations");
    }
  }, []);

  const responseGoogle = (response) => {
    console.log(response);
  };

  const successHandler = async (credentialResponse) => {
    // console.log("credentialResponse.credential", credentialResponse.credential);
    // return;
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/admin-auth/login`,
      {
        token: credentialResponse.credential,
      }
    );
    const res = response.data;
    if (res.status == "success") {
      Cookies.set(COOKIE_GOOGLE, credentialResponse.credential);
      localStorage.setItem(LS_USER_PROFILE, JSON.stringify(res.data));
      // console.log("Stored in localStorage:", localStorage.getItem(LS_USER_PROFILE));
      navigate("/organizations");
    } else {
      alert("Something went wrong");
    }
  };
  return (
    <MainContainer>
      <BackgroundImage src={bg} alt="background" />
      <SignInSection>
        <LogoImage src="/assets/p-logo.png" alt="Logo" />
        <HeaderText>Percepsense Admin</HeaderText>
        {/* <Button
          sx={{ padding: "10px 20px" }}
          variant="outlined"
          onClick={loginwithgoogle}
        >
          <FcGoogle size={"30px"} />
          <Typography
            sx={{
              marginLeft: "10px",
              fontSize: "20px",
              textTransform: "capitalize",
            }}
          >
            Sign In
          </Typography>
        </Button> */}

        <GoogleLogin
          useOneTap
          render={(renderProps) => (
            <button
              type="button"
              className=""
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <FcGoogle className="" /> Sign in with google
            </button>
          )}
          onSuccess={successHandler}
          onFailure={responseGoogle}
          cookiePolicy="single_host_origin"
        />
      </SignInSection>
    </MainContainer>
  );
};

export default Login;
