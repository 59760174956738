import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import { COOKIE_GOOGLE, LS_USER_PROFILE } from "../common/constants";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useGetUsersQuery } from "../api/usersApiSlice";
import Cookies from "js-cookie";

const MainContainer = styled.div`
  padding: 20px;
`;

export const NoDataContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

const CustomDivider = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const UserRow = styled.div`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 10px 20px;
`;

const RowHeader = styled.div`
  color: #494949;
  font-family: Poppins, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const FieldLabel = styled.div`
  color: #7e7e7e;
  font-family: Poppins, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
  margin-right: 10px;
`;

const FieldValue = styled.div`
  color: #494949;
  font-family: Poppins, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
`;

const RowField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50px;
`;

export const capitalizeWord = (str) => {
  return str[0].toUpperCase() + str.substring(1);
};

const Users = () => {
  const { data: usersList, isLoading, isError, error } = useGetUsersQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const editingData = (e) => {
    setSearchParams({
      dialog: "open",
      action: "edit",
      for: "users",
      id: e,
    });
  };

  const handleDelete = async (userId) => {
    setSearchParams({
      dialog: "open",
      action: "delete",
      for: "delUser",
      userId: userId,
    });
  };

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_PROFILE));
    setIsAdmin(localStorageData?.role === "admin");
  }, [isAdmin]);

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "60vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (isError) {
    // console.log("error: ", error);
    // if (error && error.status) {
    //   if (error.status == 403) {
    //     localStorage.removeItem(LS_USER_PROFILE);
    //     Cookies.remove(COOKIE_GOOGLE);
    //     navigate("/");
    //   }
    // }
    return <NoDataContainer>{"Something went wrong"}</NoDataContainer>;
  }

  return (
    <MainContainer>
      {usersList && usersList.length ? (
        usersList.map((data, index) => (
          <React.Fragment key={index}>
            <UserRow>
              {data.name && (
                <>
                  <RowHeader>
                    <UserImage src={data?.image} alt="Image" />
                    {data.name}
                  </RowHeader>
                  <CustomDivider />
                </>
              )}

              <RowField>
                <FieldLabel>Email:</FieldLabel>
                <FieldValue>{data.email}</FieldValue>
              </RowField>
              <RowField>
                <FieldLabel>Role:</FieldLabel>
                <FieldValue>{capitalizeWord(data?.role)}</FieldValue>
              </RowField>
              <RowField>
                <FieldLabel>Created On:</FieldLabel>
                <FieldValue>{formatDate(data.createdAt)}</FieldValue>
              </RowField>

              <CustomDivider />
              <RowField>
                <Button
                  onClick={() => editingData(data._id)}
                  variant="outlined"
                  disabled={!isAdmin}
                  endIcon={<MdEdit />}
                >
                  Edit
                </Button>
                <Box sx={{ mx: 1 }}></Box>
                <Button
                  onClick={() => handleDelete(data._id)}
                  variant="outlined"
                  disabled={!isAdmin}
                >
                  {"Delete"}
                </Button>
              </RowField>
            </UserRow>
            {index !== usersList.length - 1 ? <Box sx={{ my: 2 }} /> : null}
          </React.Fragment>
        ))
      ) : (
        <NoDataContainer>{"No Users"}</NoDataContainer>
      )}
    </MainContainer>
  );
};

export { Users };
