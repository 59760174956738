import React, { useEffect, useState } from "react";
import "./styles/addorganization.css";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { COOKIE_GOOGLE, organizationServices } from "../common/constants";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  FormControl,
} from "@mui/material";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import {
  useAddOrgMutation,
  useEditOrgMutation,
  useGetOrgsQuery,
} from "../api/orgsApiSlice";
import { showToast } from "../utility/Toast";

const CustomTextField = styled(TextField)``;

export const createDropdownOptions = (value) => {
  const dropdownmenu = organizationServices.find((e) => e.value === value);
  //  console.log(dropdownmenu)
  return dropdownmenu?.label;
};

const AddOrganization = () => {
  const token = Cookies.get(COOKIE_GOOGLE);
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const { singleOrg, error, isSuccess, isLoading } = useGetOrgsQuery(
    undefined,
    {
      skip: id ? false : true,
      selectFromResult: ({ data, error, isLoading, isSuccess }) => ({
        singleOrg: data?.find((org) => org._id === id),
        error,
        isLoading,
        isSuccess,
      }),
    }
  );
  const [addOrg, { isLoading: isLoadingAdd }] = useAddOrgMutation();
  const [editOrg, { isLoading: isLoadingEdit }] = useEditOrgMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [services, setServices] = useState([]);

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid, isSubmitted },
  } = useForm({
    defaultValues: {
      name: singleOrg ? singleOrg.name : "",
      userName: singleOrg ? singleOrg?.users[0]?.name : "",
      email: singleOrg ? singleOrg?.users[0]?.name : "",
    },
  });

  const handleOptionChange = (event) => {
    const selectedValues = event.target.value;
    setServices(selectedValues);
    console.log(selectedValues);
  };

  const onSubmit = async (data) => {
    try {
      data.services = services;
      // console.log("data",data)
      if (!id) {
        await addOrg(data);
        showToast.success("Organization added successfully.");
      } else {
        let name = data.name;
        data = { name, services };
        await editOrg({ id, data });
        showToast.success("Organization edited successfully.");
      }
      setSearchParams({});
    } catch (error) {
      console.error("An error occurred:", error);
      showToast.error("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <DialogTitle>Add Organization</DialogTitle>
      <Divider />
      <DialogContent>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CustomTextField
              {...field}
              error={errors.name}
              // helperText={errors.name ? "Name Required!" : " "}
              required
              fullWidth
              label={"Organization Name"}
            />
          )}
        />

        <Box sx={{ my: 2 }} />

        <Controller
          name="userName"
          disabled={id ? true : false}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CustomTextField
              {...field}
              error={errors.userName}
              // helperText={errors.adminName ? "Admin Name Required!" : " "}
              required
              fullWidth
              label={"Admin Name"}
            />
          )}
        />
        <Box sx={{ my: 2 }} />
        <Controller
          name="email"
          disabled={id ? true : false}
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
          }}
          render={({ field }) => (
            <CustomTextField
              type="email"
              {...field}
              error={errors.email}
              required
              fullWidth
              label={"Admin Email"}
            />
          )}
        />
        <Box sx={{ my: 2 }} />

        <Controller
          name="services"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="dropdown-label">Select Options</InputLabel>
              <Select
                {...field}
                labelId="dropdown-label"
                id="dropdown"
                multiple
                fullWidth
                value={field.value || []}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  handleOptionChange(e);
                }}
                label="Select Options"
                renderValue={(selected) =>
                  selected
                    .map(
                      (value) =>
                        organizationServices.find(
                          (option) => option.value === value
                        )?.label
                    )
                    .join(", ")
                }
              >
                {organizationServices.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox
                      checked={
                        field.value && field.value.indexOf(option.value) > -1
                      }
                    />
                    <ListItemText
                      primary={createDropdownOptions(option.value)}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Box sx={{ my: 2 }} />

        {services.includes("e2e") && (
          <>
            <Box sx={{ my: 2 }} />
            <Controller
              name="numberOfLicences"
              control={control}
              rules={{ required: services.includes("e2e"), min: 1 }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  type="number"
                  error={errors.e2eField}
                  required
                  fullWidth
                  label={"Number Of Licences"}
                />
              )}
            />
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: "25px" }}>
        <Button
          disabled={isLoadingAdd || isLoadingEdit}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          endIcon={
            isLoadingAdd || isLoadingEdit ? (
              <CircularProgress size={20} />
            ) : null
          }
        >
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

export default AddOrganization;
