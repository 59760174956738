import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { COOKIE_GOOGLE } from "../common/constants";

export const getTokenFromCookies = () => {
  const token = Cookies.get(COOKIE_GOOGLE);
  return token;
};

export const tagsApiSlice = createApi({
  reducerPath: "tags",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/v1/tags`,
  }),
  tagTypes: ["Tags"],
  endpoints: (builder) => ({
    getTags: builder.query({
      query: () => ({
        url: "/",
        method: "GET",
        // body: data,
        headers: {
          Authorization: `Bearer ${getTokenFromCookies()}`,
        },
      }),
      transformResponse: (res) => {
        if (res) {
          return res;
        } else {
          return null;
        }
      },

      providesTags: ["Tags"],
    }),

    filterTags: builder.query({
      query: (filter) => ({
        url: "/filter",
        method: "POST",
        body: { ...filter },
        headers: {
          Authorization: `Bearer ${getTokenFromCookies()}`,
        },
      }),

      transformResponse: (res) => {
        if (res) {
          return res;
        } else {
          return null;
        }
      },
      providesTags: ["Tags"],
    }),

    addTag: builder.mutation({
      query: (tagData) => ({
        url: `/`,
        method: "POST",
        body: {
          ...tagData,
        },
        headers: {
          Authorization: `Bearer ${getTokenFromCookies()}`,
        },
      }),
      invalidatesTags: ["Tags"],
    }),
    editTag: builder.mutation({
      query: ({ id, ...patch }) => {
        return {
          url: `/${id}`,
          method: "PATCH",
          body: patch.data,
          headers: {
            Authorization: `Bearer ${getTokenFromCookies()}`,
          },
        };
      },
      invalidatesTags: ["Tags"],
    }),
    deleteTag: builder.mutation({
      query: (tagID) => ({
        url: `/${tagID}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getTokenFromCookies()}`,
        },
      }),
      invalidatesTags: ["Tags"],
    }),
  }),
});

export const {
  useGetTagsQuery,
  useAddTagMutation,
  useFilterTagsQuery,
  useLazyFilterTagsQuery,
  useEditTagMutation,
  useDeleteTagMutation
} = tagsApiSlice;
