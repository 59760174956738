import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { capitalize } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const UserImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
`;
const UserName = styled.div`
  color: #000;
  text-edge: cap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const UserRole = styled.div`
  color: #444;
  text-edge: cap;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  text-transform: capitalize;
`;

const UserSection = (props) => {
  const [userData, setUserData] = useState();
  useEffect(() => {
    const userProfile = localStorage.getItem("googleUserProfile");
    setUserData(JSON.parse(userProfile));
  }, []);

  return (
    <Container>
      <UserImage src={userData?.image} alt="Image" />
      <UserDetails>
        <UserName>{userData?.name.split(" ")[0]}</UserName>
        <UserRole>{userData ? capitalize(userData?.role) : ""}</UserRole>
      </UserDetails>
    </Container>
  );
};

UserSection.propTypes = {};

export { UserSection };
