import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { COOKIE_GOOGLE } from "../common/constants";
import {
  Box,
  Button, CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import {
  useAddOrgMutation,
  useEditOrgMutation,
  useGetOrgsQuery,
} from "../api/orgsApiSlice";
import {
  useAddUserMutation,
  useEditUserMutation,
  useGetUsersQuery,
} from "../api/usersApiSlice";
import {showToast} from "../utility/Toast";

const CustomTextField = styled(TextField)``;

const rolesArray = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Member",
    value: "member",
  },
];

const AddUser = () => {
  const [role, setRole] = useState("");
  const token = Cookies.get(COOKIE_GOOGLE);
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const { singleUser, error, isSuccess, isLoading } = useGetUsersQuery(
    undefined,
    {
      skip: id ? false : true,
      selectFromResult: ({ data, error, isLoading, isSuccess }) => ({
        singleUser: data?.find((user) => user._id === id),
        error,
        isLoading,
        isSuccess,
      }),
    }
  );
  const [addUser,{isLoading: isAddUserLoading}] = useAddUserMutation();
  const [editUser, {isLoading:isEditLoading}] = useEditUserMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid, isSubmitted },
  } = useForm({
    defaultValues: {
      email: singleUser ? singleUser?.email : "",
      role: singleUser ? singleUser?.role : "",
    },
  });

  const onSubmit = async (data) => {
    try {
      if (!id) {
        const newData = { ...data };
        await addUser(newData);
        showToast.success("User added successfully.");
      } else {
        // console.log("editing data is", data);
        data = { role: data.role };
        await editUser({ id, data });
        showToast.success("User edited successfully.");
      }
      setSearchParams({});
    } catch (error) {
      console.error("An error occurred:", error);
      showToast.error("An error occurred. Please try again.");
    }
  };


  return (
    <>
      <DialogTitle>Add User</DialogTitle>
      <Divider />
      <DialogContent>
        <Controller
          name="email"
          disabled={id ? true : false}
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
          }}
          render={({ field }) => (
            <CustomTextField
              type="email"
              {...field}
              error={errors.email}
              required
              fullWidth
              label={"Admin Email"}
            />
          )}
        />

        <Box sx={{ my: 2 }} />

        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Role</InputLabel> */}
          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="Role"
            onChange={(evt) => setRole(evt.target.value)}
          >
            {rolesArray.map((singleRole, index) => {
              return (
                <MenuItem key={singleRole.value} value={singleRole.value}>
                  {singleRole.label}
                </MenuItem>
              );
            })}
          </Select> */}

          <Controller
            name="role"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  {...field}
                  label="Role"
                  error={errors.role}
                >
                  {rolesArray.map((singleRole) => (
                    <MenuItem key={singleRole.value} value={singleRole.value}>
                      {singleRole.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </FormControl>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: "25px" }}>
        <Button
          disabled={ isAddUserLoading || isEditLoading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          endIcon={
            (isAddUserLoading || isEditLoading) ? <CircularProgress size={20}/> : null
          }
        >
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

export default AddUser;
