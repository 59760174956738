import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import { UserSection } from "./UserSection";
import { FaPlus } from "react-icons/fa";
import { LS_USER_PROFILE } from "../common/constants";

const HeaderContainer = styled.div`
  width: 100%;
  height: 100px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
`;
const HeaderText = styled.div`
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  color: #134e4a;
  margin-right: 10px;
`;

const HeaderAction = styled.div`
  width: 100%;
  padding: 10px;
`;

const CustomButton = styled(Button)`
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 12px;
  border: 1px solid #134e4a;
`;

const mapperObj = {
  "/organizations": { label: "Organizations", param: "organizations" },
  "/tags": { label: "Tags", param: "tags" },
  "/users": { label: "Users", param: "users" },
};



const Header = (props) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_PROFILE));
    setIsAdmin(localStorageData?.role === "admin");
  }, [isAdmin]);

  const handleAddClick = () => {
    setSearchParams({
      dialog: "open",
      action: "add",
      for: mapperObj[pathname].param,
    });
  };

  return (
    <HeaderContainer>
      <HeaderText>{mapperObj[pathname].label} </HeaderText>
      <HeaderAction>
        <CustomButton size="small" variant="outlined" onClick={handleAddClick} disabled={!isAdmin} >
          Add
          <FaPlus style={{ marginLeft: "5px" }} />
        </CustomButton>
      </HeaderAction>
      <UserSection />
    </HeaderContainer>
  );
};

export { Header };
