import styled from "@emotion/styled";
import { IconButton, Input, TextField, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { NoDataContainer } from "../pages/organizations";

const TagChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 5px 10px;
`;

const ColorsContainer = styled.div`
  overflow: scroll;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const TagsContainer = styled.div`
  border: 1px dashed #ccc;
  border-radius: 5px;
  height: 150px;
  overflow: scroll;
  padding: 10px;
`;

function getTextColor(backgroundHexColor) {
  // Remove '#' if present
  const hex = backgroundHexColor.replace("#", "");

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Use black or white text depending on luminance
  return luminance > 0.5 ? "#000000" : "#ffffff";
}

const TagInput = ({ onChange, value }) => {
  const [tags, setTags] = useState([...value]);
  const [inputValue, setInputValue] = useState("");
  const [selectedColor, setSelectedColor] = useState(0); // Default color
  const tagColors = [
    "#FF6347", // Tomato
    "#4682B4", // Steel Blue
    "#7FFFD4", // Aquamarine
    "#FFD700", // Gold
    "#BA55D3", // Medium Orchid
    "#32CD32", // Lime Green
    "#9932CC", // Dark Orchid
    "#FF8C00", // Dark Orange
    "#4B0082", // Indigo
    "#20B2AA", // Light Sea Green
  ];

  useEffect(() => {
    onChange(tags);
  }, [tags]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      setTags([
        ...tags,
        { label: inputValue.trim(), color: tagColors[selectedColor] },
      ]);
      setInputValue("");
    }
  };

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter((tag) => tag.label !== tagToRemove.label));
  };

  return (
    <div>
      <div>
        <TextField
          fullWidth
          label={"Add Tags"}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder="Type tag name and press Enter"
        />
        <ColorsContainer>
          {tagColors.map((color, index) => {
            return (
              <div
                onClick={() => {
                  setSelectedColor(index);
                }}
                style={{
                  cursor: "pointer",
                  width: "30px",
                  height: "30px",
                  borderRadius: "3px",
                  backgroundColor: color,
                  border: index == selectedColor ? `2px solid #000` : "none",
                }}
              ></div>
            );
          })}
        </ColorsContainer>

        <TagsContainer>
          {tags && tags.length ? (
            tags.map((tag, index) => (
              <div
                key={index}
                style={{ display: "inline-block", margin: "5px" }}
              >
                <TagChip
                  style={{
                    backgroundColor: tag.color,
                    color: getTextColor(tag.color),
                  }}
                >
                  {tag.label}
                  <IconButton size="small" onClick={() => handleTagRemove(tag)}>
                    <MdCancel color={getTextColor(tag.color)} />
                  </IconButton>
                </TagChip>
              </div>
            ))
          ) : (
            <NoDataContainer>No tags added</NoDataContainer>
          )}
        </TagsContainer>
      </div>
    </div>
  );
};

export default TagInput;
