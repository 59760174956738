import { createTheme } from "@mui/material";

export const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {

                }
        },
      },
    },
    spacing: 8,
    palette: {
        mode: 'light',
        primary: {
            main: '#1b4650',
        },
        secondary: {
            main: '#feefec',
        },
    }
});