import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import "./styles/tags.css";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import TagInput from "../common/TagInput";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button, CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import { COOKIE_GOOGLE } from "../common/constants";
import {
  useAddTagMutation,
  useEditTagMutation,
  useGetTagsQuery,
} from "../api/tagsApiSlice";
import {showToast} from "../utility/Toast";

const Container = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 35px;
`;

const OverallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  max-width: 600px;
`;

const ContentContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LabelText = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
`;
const Input = styled.input`
  height: 40px;
  font-size: 1.6rem;
`;
const ButtonContainer = styled.button`
  outline: none;
  max-width: 200px;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const AddTags = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const { singleTag, error, isSuccess, isLoading } = useGetTagsQuery(
    undefined,
    {
      skip: id ? false : true,
      selectFromResult: ({ data, error, isLoading, isSuccess }) => ({
        singleTag: data?.find((tag) => tag._id === id),
        error,
        isLoading,
        isSuccess,
      }),
    }
  );
  const [addTag, {isLoading:isLoadingAdd}] = useAddTagMutation();
  const [editTag, {isLoading: isLoadingEdit} ] = useEditTagMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isDirty, isValid, isSubmitted },
  } = useForm({
    defaultValues: {
      projectType: id ? singleTag.projectType : "",
      category: id ? singleTag.category : "",
      tags: id ? singleTag.tags : [],
    },
  });

  // const onSubmit = async (data) => {
  //   if (!id) {
  //     addTag(data);
  //     showToast.success("Tag added successfully.");
  //   } else {
  //     editTag({ id, data });
  //     showToast.success("Tag edited successfully.");
  //   }
  //   setSearchParams({});
  // };

  console.log(isLoadingAdd);
  console.log("loader ",isLoadingEdit)

  const onSubmit = async (data) => {
    try {
      if (!id) {
        await addTag(data);
        showToast.success("Tag added successfully.");
      } else {
        await editTag({ id, data });
        showToast.success("Tag edited successfully.");
      }
      setSearchParams({});
    } catch (error) {
      // Handle errors appropriately
      console.error("An error occurred:", error);
      showToast.error("An error occurred. Please try again.");
    }
  };

  if (id && isLoading) {
    return "Loading";
  }
  return (
    <>
      <DialogTitle>{!id ? "Add Tags" : "Edit Tags"}</DialogTitle>
      <Divider />
      <DialogContent>
        <Controller
          name="projectType"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors.name}
              // helperText={errors.name ? "Name Required!" : " "}
              required
              fullWidth
              label={"Project Type"}
            />
          )}
        />

        <Box sx={{ my: 2 }} />

        <Controller
          name="category"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors.name}
              // helperText={errors.name ? "Name Required!" : " "}
              required
              fullWidth
              label={"Category"}
            />
          )}
        />

        <Box sx={{ my: 2 }} />

        <Controller
          name="tags"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            console.log("Inside controller - looking for field");
            return <TagInput {...field} />;
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: "25px" }}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}
                disabled={isLoadingAdd || isLoadingEdit}
                endIcon={
                  (isLoadingAdd || isLoadingEdit) ? <CircularProgress size={20}/> : null
                }
        >
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

export { AddTags };
