import React, { useEffect, useState } from "react";
import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  Outlet,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import Login from "./components/Login";
import Navbar from "./components/LeftSection";
import Addorganization from "./pages/addorganization";
import { Header } from "./components/Header";
import styled from "@emotion/styled";
import Taglisting from "./pages/Tags";
import CustomDialog from "./components/common";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/mui.theme";
import { Users } from "./pages/Users";
import organizations from "./pages/organizations";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Cookies from "js-cookie";
import { COOKIE_GOOGLE } from "./common/constants";
import { Provider } from "react-redux";
import { store } from "./api/store";
import Organizations from "./pages/organizations";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { GoogleLogin } from '@react-oauth/google';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100vh;
  padding: 10px;
  background-color: #f2f2f2;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const MainBody = styled.div`
  overflow: scroll;
  flex-grow: 1;
  border-radius: 10px;
  background-color: #fff;
`;

const HeaderLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let cookie = Cookies.get(COOKIE_GOOGLE);
    if (!cookie) {
      navigate("/");
    }
  }, []);

  return (
    <Container>
      <Navbar />
      <RightSection>
        <Header />
        <MainBody>
          <Outlet />
        </MainBody>
      </RightSection>
      <CustomDialog />
    </Container>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/",
    element: <HeaderLayout />,
    children: [
      {
        path: "/organizations",
        element: <Organizations />,
      },
      {
        path: "/tags",
        element: <Taglisting />,
      },
      {
        path: "/users",
        element: <Users />,
      },
    ],
  },
]);

function App() {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
          <ToastContainer/>
        </ThemeProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
