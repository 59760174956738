import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import "./styles/taglisting.css";
import { useSearchParams } from "react-router-dom";
import { NoDataContainer } from "./organizations";
import {
  CustomDivider,
  DataRow,
  FieldLabel,
  FieldValue,
  MainContainer,
  RowField,
} from "../common/styles";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
} from "@mui/material";
import {
  useFilterTagsQuery,
  useGetTagsQuery,
  useGetUniqueProjectTypesQuery,
  useLazyFilterTagsQuery,
} from "../api/tagsApiSlice";
import { LS_USER_PROFILE } from "../common/constants";
import CustomPagination from "../components/CustomPagination";
import Select from "react-select";

const TagChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 5px 10px;
`;

const TagsList = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 0px;
  flex-wrap: wrap;
`;

const SingleAttributes = styled.div`
  border: 3px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: 500;
  padding: 0.5rem;
  font-size: 1.6rem;
  max-width: 100px;
  width: 100%;
`;
const HeaderText = styled.p`
  font-size: 3rem;
  font-weight: 500;
  text-transform: capitalize;
`;

const FilterWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
`;

const Filter = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 25%;
  margin-bottom: 1rem;
`;

const CustomReactSelect = styled(Select)`
  .react-select__control {
    height: 45px;
    outline: none;
    border: 2px solid #134f4b;
    box-shadow: none;
    &:hover {
      border: 2px solid #134f4b;
    }
  }

  .react-select__option--is-selected {
    background: #134f4b;
  }

  .react-select__option--is-focused {
    background: #d4f8e8;
  }
`;

const Taglisting = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const [tagsData, setTagsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedProjectTypes, setSelectedProjectTypes] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [triggerTags] = useLazyFilterTagsQuery();

  const { data: tags } = useFilterTagsQuery({
    pageNo: 0,
    projectType: selectedProjectTypes?.value,
  });

  const apiFetch = async () => {
    setIsLoading(true);
    const queryOptions = {
      pageNo: page,
    };
    if (selectedProjectTypes?.value) {
      queryOptions.projectType = selectedProjectTypes.value;
    }

    if (selectedCategory?.value) {
      queryOptions.category = selectedCategory.value;
    }

    try {
      const { data, isLoading, isSuccess, isError } = await triggerTags(
        queryOptions
      );
      if (isSuccess) {
        setIsSuccess(isSuccess);
        setTagsData(data);
        setIsLoading(isLoading);
      }
      if (isError) {
        setIsError(isError);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_PROFILE));
    setIsAdmin(localStorageData?.role === "admin");
  }, [isAdmin]);

  useEffect(() => {
    if (!page) {
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    } else {
      apiFetch();
    }
  }, [page, selectedProjectTypes, selectedCategory]);

  const handlePageChange = (event, value) => {
    // event.stopPropagation();
    console.log("page change working");
    if (page) {
      searchParams.delete("page");
    }
    searchParams.append("page", value.toString());
    setSearchParams(searchParams);
  };

  const handleChange = (e) => {
    setSelectedProjectTypes(e);
    setSelectedCategory(null);
  };

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "60vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (isError) {
    return <NoDataContainer>{"Something went wrong"}</NoDataContainer>;
  }
  if (isSuccess) {
    return (
      <MainContainer>
        <FilterWrapper>
          <Filter>
            <p>Select project type</p>
            <CustomReactSelect
              defaultValue={selectedProjectTypes}
              onChange={handleChange}
              options={
                tags?.uniqueProjectTypes.map((type) => ({
                  value: type,
                  label: type,
                }))
              }
              placeholder="Select project type"
              className="basic-single"
              classNamePrefix="react-select"
              isClearable
              isSearchable
            />
          </Filter>

          <Filter>
            <p>Select Category</p>
            <CustomReactSelect
              defaultValue={selectedCategory}
              onChange={setSelectedCategory}
              options={
                tags?.data?.map((ele) => {
                  return { value: ele.category, label: ele.category };
                })
              }
              placeholder="Select Category"
              classNamePrefix="react-select"
              isClearable
              isSearchable
            />
          </Filter>
        </FilterWrapper>
        {tagsData && tagsData?.data?.length ? (
          tagsData?.data?.map((singleTag, index) => (
            <>
              <DataRow key={singleTag._id}>
                <RowField key={`ProjectType_${index}`}>
                  <FieldLabel>Project Type:</FieldLabel>
                  <FieldValue>{singleTag.projectType}</FieldValue>
                </RowField>
                <RowField key={`Category_${index}`}>
                  <FieldLabel>Category:</FieldLabel>
                  <FieldValue>{singleTag.category}</FieldValue>
                </RowField>

                <CustomDivider />

                <TagsList key={`TagsList_${index}`}>
                  {singleTag?.tags.map((tagObj, innerIndex) => (
                    <TagChip
                      style={{ backgroundColor: tagObj.color }}
                      key={`inner_${innerIndex}`}
                    >
                      {tagObj.label}
                    </TagChip>
                  ))}
                </TagsList>
                <CustomDivider />
                <RowField>
                  <Button variant="outlined" disabled={!isAdmin} endIcon={<MdEdit />}>
                    Edit
                  </Button>
                  <Box sx={{ mx: 1 }}></Box>
                  <Button
                    variant="outlined"
                    disabled={!isAdmin}
                    endIcon={<MdDelete />}
                  >
                    Delete
                  </Button>
                </RowField>
              </DataRow>
              {index !== tagsData.length - 1 ? (
                <Box key={`box_${index}`} sx={{ my: 2 }} />
              ) : null}
            </>
          ))
        ) : (
          <NoDataContainer>No tags</NoDataContainer>
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          {!isLoading && tagsData?.count ? (
            <CustomPagination
              count={tagsData?.count ? Math.ceil(tagsData.count / 10) : 0}
              onChange={handlePageChange}
              pageNo={parseInt(page || "1")}
            />
          ) : null}
        </div>
      </MainContainer>
    );
  }
};

export default Taglisting;
