import React from "react";
import { Button, Modal } from "antd";
import { useSearchParams } from "react-router-dom";
// import Organizationlisting from "../pages/Organizations";
import Taglisting from "../pages/Tags";
// import AddOrganization from "../pages/AddOrganization";
import { DialogActions, DialogTitle } from "@mui/material";
import AddUser from "../pages/AddUser";
import AddOrganization from './../pages/addorganization';
import { AddTags } from "../pages/addtags";

const DialogHandler = (props) => {
  const { subject, action, id } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClose = () => {
    setSearchParams({});
  };

  const prepDialogContent = () => {
    switch (props.subject) {
      case "organizations":
        return <AddOrganization type={props.action} />;
      case "tags":
        return <AddTags {...props} />;
      case "users":
        return <AddUser {...props} />;
      default:
        return "Default";
    }
  };
  return <>{prepDialogContent()}</>;
};

export { DialogHandler };
