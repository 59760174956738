import React, { useEffect, useState } from "react";
import "./styles/organizations.css";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import {
  COOKIE_GOOGLE,
  LS_USER_PROFILE,
  organizationServices,
} from "../common/constants";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useGetOrgsQuery } from "../api/orgsApiSlice";
import { createDropdownOptions } from "./addorganization";
import { MdStarBorderPurple500 } from "react-icons/md";

const MainContainer = styled.div`
  padding: 20px;
`;

export const NoDataContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

const CustomDivider = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const OrgRow = styled.div`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 10px 20px;
`;

const RowHeader = styled.div`
  color: #494949;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
`;

const FieldLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FieldLabel = styled.div`
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
  margin-right: 10px;
`;

const FieldValue = styled.div`
  color: #494949;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
`;

const RowField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Organizations = () => {
  const navigate = useNavigate();
  const {
    data: orgList,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetOrgsQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const editingData = (e) => {
    setSearchParams({
      dialog: "open",
      action: "edit",
      for: "organizations",
      id: e,
    });
  };

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_PROFILE));
    setIsAdmin(localStorageData?.role === "admin");
  }, [isAdmin]);

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "60vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (isError) {
    // console.log("error: ", error);
    // if (error && error.status) {
    //   if (error.status == 403) {
    //     localStorage.removeItem(LS_USER_PROFILE);
    //     Cookies.remove(COOKIE_GOOGLE);
    //     navigate("/");
    //   }
    // }
    return <NoDataContainer>{"Something went wrong"}</NoDataContainer>;
  }

  return (
    <MainContainer>
      {orgList && orgList.length ? (
        orgList.map((data, index) => (
          <>
            <OrgRow key={index}>
              <RowHeader>{data.name}</RowHeader>
              <CustomDivider />
              <RowField>
                <FieldLabel>Created On:</FieldLabel>
                <FieldValue>{formatDate(data.createdAt)}</FieldValue>
              </RowField>
              <RowField>
                <FieldLabel>Total Organization Members:</FieldLabel>
                <FieldValue>{data.users ? data.users.length : 0}</FieldValue>
              </RowField>
              <RowField>
                <FieldLabel>Services:</FieldLabel>
                <FieldValue>
                  {data.subscriptionId &&
                  data.subscriptionId.services &&
                  data.subscriptionId.services.length > 0 ? (
                    <ul style={{ display: "inline-flex", gap: "10px" }}>
                      {data.subscriptionId.services.map(
                        (service, serviceIndex) => (
                          <li key={serviceIndex}>
                            {createDropdownOptions(service)}
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    "No services available"
                  )}
                </FieldValue>
              </RowField>
              <RowField>
                <FieldLabel> Number Of Licences:</FieldLabel>
                <FieldValue>
                  {data?.numberOfLicences ? data?.numberOfLicences : 0}
                </FieldValue>
              </RowField>
              <CustomDivider />
              <RowField>
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {data.users &&
                    data.users.map((user, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            border: "1px solid #EEE",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          <FieldLabelWrapper>
                            <FieldLabel>{user.name}</FieldLabel>
                            {user?.hasLicence && (
                              <MdStarBorderPurple500 color="#417b8a" />
                            )}
                          </FieldLabelWrapper>
                          <FieldValue>{`Role: ${user.role}`}</FieldValue>
                          <FieldValue>{`Email: ${user.email}`}</FieldValue>
                        </div>
                      );
                    })}
                </div>
              </RowField>
              <CustomDivider />
              <RowField>
                <Button
                  onClick={() => editingData(data._id)}
                  variant="outlined"
                  disabled={!isAdmin}
                  endIcon={<MdEdit />}
                >
                  Edit
                </Button>
                <Box sx={{ mx: 1 }}></Box>
                <Button
                  onClick={() => editingData(data._id)}
                  variant="outlined"
                  disabled={!isAdmin}
                  endIcon={<MdDelete />}
                >
                  Delete
                </Button>
              </RowField>
            </OrgRow>
            {index != data.length - 1 ? <Box sx={{ my: 2 }} /> : null}
          </>
        ))
      ) : (
        <NoDataContainer>{"No organizations"}</NoDataContainer>
      )}
    </MainContainer>
  );
};

export default Organizations;
