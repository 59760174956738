import { GrOrganization } from "react-icons/gr";
import { IoPricetags } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
export const linksArray = [
  {
    to: "/users",
    label: "Users",
    title: "Users",
    icon: <FaUser style={{ marginRight: "10px" }} />,
  },
  {
    to: "/organizations",
    label: "Organizations",
    title: "Organizations",
    icon: <GrOrganization style={{ marginRight: "10px" }} />,
  },
  {
    to: "/tags",
    label: "Tags",
    title: "Tags",
    icon: <IoPricetags style={{ marginRight: "10px" }} />,
  },
];

export const organizationServices = [
  {
    label: "Qual@E2E",
    value: "e2e",
  },
  {
    label: "Qual@Analyze",
    value: "analyze",
  },
];

export const LS_USER_PROFILE = "googleUserProfile";
export const COOKIE_GOOGLE = "google_credential";
