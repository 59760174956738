import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { COOKIE_GOOGLE } from "../common/constants";

export const getTokenFromCookies = () => {
  const token = Cookies.get(COOKIE_GOOGLE);
  return token;
};

export const orgsApiSlice = createApi({
  reducerPath: "orgs",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/v1/organization`,
  }),
  tagTypes: ["Orgs"],
  endpoints: (builder) => ({
    getOrgs: builder.query({
      query: () => ({
        url: "/listOrgWithUser",
        method: "GET",
        // body: data,
        headers: {
          Authorization: `Bearer ${getTokenFromCookies()}`,
        },
      }),
      transformResponse: (res) => {
        if (res) {
          return res;
        } else {
          return null;
        }
      },

      providesTags: ["Orgs"],
    }),

    addOrg: builder.mutation({
      query: (tagData) => ({
        url: `/createOrgWithUser`,
        method: "POST",
        body: {
          ...tagData,
        },
        headers: {
          Authorization: `Bearer ${getTokenFromCookies()}`,
        },
      }),
      invalidatesTags: ["Orgs"],
    }),
    editOrg: builder.mutation({
      query: ({ id, ...patch }) => {
        return {
          url: `/${id}`,
          method: "PATCH",
          body: patch.data,
          headers: {
            Authorization: `Bearer ${getTokenFromCookies()}`,
          },
        };
      },
      invalidatesTags: ["Orgs"],
    }),
    deleteOrg: builder.mutation({
      query: (tagID) => ({
        url: `/${tagID}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getTokenFromCookies()}`,
        },
      }),
      invalidatesTags: ["Orgs"],
    }),
  }),
});

export const {
  useGetOrgsQuery,
  useAddOrgMutation,
  useEditOrgMutation,
  useDeleteOrgMutation,
} = orgsApiSlice;
