import React, { useEffect, useState } from "react";
import "./styles/navbar.css";
import { GrOrganization } from "react-icons/gr";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { IoPricetags } from "react-icons/io5";
import { IoReorderThree } from "react-icons/io5";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { GiCrossedBones } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { RiLogoutCircleLine } from "react-icons/ri";
import styled from "@emotion/styled";
import {
  COOKIE_GOOGLE,
  LS_USER_PROFILE,
  linksArray,
} from "../common/constants";
import { Button } from "@mui/material";
import logoSolid from "../images/logo-solid.png";
import Cookies from "js-cookie";

const NavContainer = styled.div`
  min-width: 245px;
  height: 100%;
  background-color: #134e4a;
  padding: 20px;
  border-radius: 10px;
`;

const StyledLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(19, 78, 74);
  img.img-active {
    display: none;
  }
  &.active {
    background-color: white;
    color: #134e4a;
    img.img-inactive {
      display: none;
    }
    img.img-active {
      display: block;
    }
  }
  &:hover {
    border: 1px solid #fff;
  }
  margin-bottom: 20px;
`;

const LinksGroup = styled.div`
  margin-top: 30px;
`;

const LogoutSection = styled.div`
  border-top: 1px solid #fff;
  padding-top: 20px;
`;

const LogoutButton = styled(Button)`
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  border-bottom: 1px solid #134e4a;
  &:hover {
    border-bottom: 1px solid #fff;
  }
`;

const BrandingSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const Logo = styled.img`
  margin-right: 10px;
`;

const BrandName = styled.div`
  color: #fff;

  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 180% */
`;

const LeftSection = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem(LS_USER_PROFILE);
    Cookies.remove(COOKIE_GOOGLE);
    navigate("/");
  };

  return (
    <NavContainer>
      <div className="total-nav-content-with-logout-button">
        <BrandingSection>
          <Logo src={logoSolid} alt="Logo"></Logo>
          <BrandName>Perceptech</BrandName>
        </BrandingSection>
        <div className="total-navbar-content">
          <LinksGroup>
            {linksArray.map(({ to, label, icon }, index) => {
              return (
                <StyledLink key={index} to={to}>
                  {icon}
                  {label}
                </StyledLink>
              );
            })}
          </LinksGroup>
        </div>
        <LogoutSection>
          <LogoutButton variant="text" onClick={logout}>
            <RiLogoutCircleLine style={{ marginRight: "10px" }} />
            Logout
          </LogoutButton>
        </LogoutSection>
      </div>
    </NavContainer>
  );
};

export default LeftSection;
