import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal } from "antd";
import { DialogHandler } from "./DialogHandler";
import { Dialog } from "@mui/material";

const CustomDialog = () => {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    setOpen(false);
    setSearchParams({});
  };

  useEffect(() => {
    if (searchParams.get("dialog")) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [searchParams]);

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogHandler
        subject={searchParams.get("for")}
        action={searchParams.get("action")}
        id={searchParams.get("id")}
      />
    </Dialog>
  );
};

export default CustomDialog;
